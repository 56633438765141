import { Widget } from "@typeform/embed-react";
import styled from "styled-components";

export const FormPage = () => {
  return (
    <section>
      <div
        style={{
          height: "100vh",
          marginTop: "5vh",
          display: "flex",
          alignContent: "center",
          flexDirection: "column",
        }}
      >
        <Widget
          id="kCRxRQVP"
          style={{ fontSize: "16px", width: "100%" }}
          height={800}
        />

        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            marginBottom: "200px",
          }}
        >
          <FormBackButton href="/">I'll be back</FormBackButton>
        </div>
      </div>
    </section>
  );
};

const FormBackButton = styled.a`
  width: 85%;
  background-color: #222;
  color: white;
  display: block;
  text-align: center;
  border: 1px solid white;
  padding: 0.5rem 1rem;
`;
